<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="IframePreviewContainer">
                <ion-card-header id="iframePreviewHeader">
                    <div id="decorationCircle1">
                        <div></div>
                    </div>
                    <div id="decorationCircle2"></div>
                    <img src="@/assets/images/desktopRegistration.png" />
                    <div id="iframePreviewHeaderContent">
                        <h3>Test Link Preview</h3>
                        <h1>WeAreTesters</h1>
                        <div id="iframePreviewHeaderDecorationLine"></div>
                    </div>
                </ion-card-header>
                <ion-card-content id="iframePreviewContent">
                    <div class="iframe--container">
                        <iframe :src="decodedUrl" class="iframe" frameborder="0"></iframe>
                    </div>
                </ion-card-content>
            </div>
        </ion-content>
    </ion-page>
</template>



<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

// Usar `useRoute` para obtener los parámetros de la URL
const route = useRoute();

// Obtener el parámetro 'url' de la URL
const urlParam = computed(() => route.query.url || '');

// Decodificar la URL
const decodedUrl = computed(() => {
    console.log(decodeURIComponent(urlParam.value))
    return decodeURIComponent(urlParam.value)
});
</script>

<style lang="scss" scoped>
.iframe--container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Ocupa toda la altura del contenedor disponible */
    width: 100%;
    /* Ocupa toda la anchura del contenedor disponible */
}

.iframe {
    width: 100%;
    height: 100%;
    /* Asegura que el iframe ocupe todo el contenedor */
    border: none;
    /* Elimina los bordes del iframe */
}

.IframePreviewContainer {
    background: linear-gradient(180deg, #3e216c 0%, #895ad2 100%);
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Asegura que el contenedor principal ocupe toda la altura del ion-content */

    #iframePreviewHeader {
        padding-top: 70px;
        position: relative;
        flex: 0 1 auto;
        /* El encabezado toma el espacio necesario */

        #decorationCircle1 {
            background: linear-gradient(70.59deg, #341466 5.34%, #895ad2 96.14%),
                linear-gradient(180deg,
                    rgba(0, 0, 0, 0.7) 0%,
                    rgba(0, 0, 0, 0) 17.93%,
                    rgba(0, 0, 0, 0.0628259) 59.07%,
                    rgba(0, 0, 0, 0.534021) 101.26%),
                linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49));
            width: 280px;
            height: 280px;
            position: absolute;
            left: 231px;
            top: -90px;
            z-index: 0;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                border-radius: 50%;
                width: 200px;
                height: 200px;
                background: linear-gradient(180deg, #3e216c 0%, #582d99 100%);
            }
        }

        #decorationCircle2 {
            width: 224px;
            height: 224px;
            position: absolute;
            top: 88px;
            left: -107px;
            opacity: 10%;
            background: #fcfaff;
            border-radius: 50%;
        }

        >img {
            display: none;
        }

        #iframePreviewHeaderContent {
            background-color: transparent;

            h3 {
                margin: 0px;
                font-family: 'Lato';
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
            }

            h1 {
                margin: 0px;
                font-family: 'Bariol';
                font-size: 33px;
                font-weight: 700;
                color: #ffffff;
            }

            div {
                background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
                height: 4px;
                width: 60px;
                margin-top: 10px;
            }

            p {
                font-family: 'Lato';
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                position: relative;
                z-index: 1;
            }
        }
    }

    #iframePreviewContent {
        flex: 1;
        /* Ocupa el espacio restante después del encabezado */
        background-color: white;
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>
